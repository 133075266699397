import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='container'>
        © 2022 Copyright: CNC-pro Kft Created by:{' '}
        <a href='www.gerikov.hu'>gerikov.hu</a>
      </div>
    </div>
  );
};

export default Footer;
